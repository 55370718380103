var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-2"},[_c('v-card-title',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(("SHIP_ARRIVAL.HISTORY.STATUS." + (_vm.history.statusType))))+" ")]),_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldCarsImportVolume !== _vm.history.arrivalUpdate.updatedCarsImportVolume }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.IMPORT_CARS'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.history.arrivalUpdate.oldCarsImportVolume))]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldCarsImportVolume !== _vm.history.arrivalUpdate.updatedCarsImportVolume },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(" "+_vm._s(_vm.history.arrivalUpdate.updatedCarsImportVolume)+" ")])],1),_c('div',{staticClass:"mt-2 d-flex",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldCarsExportVolume !== _vm.history.arrivalUpdate.updatedCarsExportVolume }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.EXPORT_CARS'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.history.arrivalUpdate.oldCarsExportVolume)+" ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldCarsExportVolume !== _vm.history.arrivalUpdate.updatedCarsExportVolume },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(_vm._s(_vm.history.arrivalUpdate.updatedCarsExportVolume))])],1)]),_c('v-col',[_c('div',{staticClass:"d-flex",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldHeavyImportVolume !== _vm.history.arrivalUpdate.updatedHeavyImportVolume }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.IMPORT_HEAVY'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.history.arrivalUpdate.oldHeavyImportVolume)+" ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldHeavyImportVolume !== _vm.history.arrivalUpdate.updatedHeavyImportVolume },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(_vm._s(_vm.history.arrivalUpdate.updatedHeavyImportVolume))])],1),_c('div',{staticClass:"mt-2 d-flex",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldHeavyExportVolume !== _vm.history.arrivalUpdate.updatedHeavyExportVolume }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.EXPORT_HEAVY'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.history.arrivalUpdate.oldHeavyExportVolume)+" ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.arrivalUpdate.oldHeavyExportVolume !== _vm.history.arrivalUpdate.updatedHeavyExportVolume },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(_vm._s(_vm.history.arrivalUpdate.updatedHeavyExportVolume))])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }