

































































import {Component, Prop, Vue} from 'vue-property-decorator';
import StatusHistory from '../../../models/StatusHistory.model';
import ShipArrival from '../../../models/ShipArrival.model';

@Component({})
export default class HistoryArrivalUpdateComponent extends Vue {

  @Prop({ default: () => new StatusHistory() })
  public history!: StatusHistory;

  @Prop({ default: () => new ShipArrival() })
  public shipArrival!: ShipArrival;

}

